<script setup lang="ts">
import { computed } from 'vue'
import type { DropDownOption } from '../models/DropDownContents'

const AVAILABLE_MODELS = [
  'cupra/born',
  'cupra/formentor',
  'cupra/leonsportstourer',
  'cupra/leon',
  'cupra/tavascan',
  'cupra/terramar'
]

const props = defineProps<{
  modelValue: string | undefined
  carModels: Array<DropDownOption>
}>()

const CARMODEL_MULTIPLE_DELIMITER = '_'

defineEmits(['update:modelValue'])

function isActive(carModelSlug: string) {
  if (!props.modelValue) {
    return true
  }

  if (props.modelValue.includes(CARMODEL_MULTIPLE_DELIMITER)) {
    // multiple models active
    const activeSlugs = props.modelValue.split(CARMODEL_MULTIPLE_DELIMITER)
    return activeSlugs.includes(carModelSlug)
  }

  return props.modelValue === carModelSlug
}

const filteredCarModels = computed(() =>
  props.carModels.filter((cm) => AVAILABLE_MODELS.indexOf(cm.key) > -1)
)
const gridClasses = computed(() => {
  const nCarModels = filteredCarModels.value.length
  return `lg:grid-cols-${nCarModels} grid-cols-${Math.ceil(nCarModels / 2)}`
})
</script>

<template>
  <div class="grid gap-4" :class="gridClasses">
    <a
      href="#"
      v-for="carModel in filteredCarModels"
      :key="carModel.key"
      @click.prevent="$emit('update:modelValue', modelValue === carModel.key ? '' : carModel.key)"
      class="flex flex-col justify-between scale-100 motion-safe:betterhover:hover:scale-[1.1] motion-safe:betterhover:hover:opacity-100 transition-all duration-250 text-center text-sm lg:text-md"
      :class="isActive(carModel.key) ? 'opacity-100' : 'opacity-50'"
    >
      <img :src="`/img/models/${carModel.key}.png`" />
      <div class="flex justify-center items-center gap-2">
        <div class="flex-initial">CUPRA<br />{{ carModel.display_value }}</div>
        <span class="flex-none inline-flex py-0.5 px-1.5 rounded-full bg-petrol text-white text-xs">
          {{ carModel.count }}
        </span>
      </div>
    </a>
  </div>
</template>
