<script setup lang="ts">
interface Props {
  variant: 'primary' | 'secondary'
  type: 'submit' | 'reset' | 'button'
}
const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  type: 'button'
})

let classes: string
switch (props.variant) {
  case 'secondary':
    classes = 'text-center border border-oxygen bg-cupragray text-oxygen font-medium mt-4 py-3 px-6'
    break
  default:
    classes = 'text-center border border-petrol bg-petrol text-oxygen font-medium mt-8 py-3 px-6'
    break
}
</script>

<template>
  <button :class="classes" :type="type">
    <slot></slot>
  </button>
</template>
