import { createRouter, createWebHistory } from 'vue-router'
import ListView from '../views/ListView.vue'
import DetailView from '../views/DetailView.vue'
import CompareView from '../views/CompareView.vue'
import config from '@/config'
import LeadView from '@/views/LeadView.vue'

const router = createRouter({
  history: createWebHistory(config.baseUrl),
  routes: [
    {
      path: '/vergelijk/:ids',
      name: 'compare',
      component: CompareView,
      props: true
    },
    {
      path: '/interesse/:id',
      name: 'lead',
      component: LeadView,
      props: true
    },
    {
      path: '/:model?',
      name: 'list',
      component: ListView
    },
    {
      path: '/:slug/:id',
      name: 'legacy-detail',
      redirect: (to) => {
        // Old stock-new routes
        const stockNewMatches = to.path.match(/\/([a-z]*)\/(.*)-(\d{6})/)

        if (stockNewMatches) {
          return {
            path: `/${stockNewMatches[1]}/${stockNewMatches[2]}/${stockNewMatches[3]}`
          }
        }

        // Other stock-new routes
        const stockNewMatches2 = to.path.match(/\/([a-z]*)\/(\d{6})/)
        if (stockNewMatches2) {
          return {
            path: `/${stockNewMatches2[1]}/cupra-${stockNewMatches2[1]}/${stockNewMatches2[2]}`
          }
        }

        // Old stock-used routes
        const matches = to.path.match(/[A-Za-z]*-([A-Za-z]*)-/)

        if (matches && matches.length === 2) {
          return {
            path: `/${matches[1]}${to.path}`
          }
        } else {
          return {
            path: '/'
          }
        }
      }
    },
    {
      path: '/:model/:slug/:id/:garageId?',
      name: 'detail',
      component: DetailView,
      props: true
    }
  ],
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
