import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'

import config from './config'

import App from './App.vue'
import router from './router'

declare global {
  interface Window {
    fbq?: any // to make use of Facebook Pixel which is possibly loaded by Tealium
  }
}

const app = createApp(App)

if (config.isProd) {
  Sentry.init({
    app,
    dsn: 'https://eccee2bdde07d6eab921a7d82175474c@o4506127572008960.ingest.us.sentry.io/4507525395644416',
    // environment:
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    allowUrls: [/cupraofficial\.nl/, /cupraunofficial\.nl/, /netlify\.app/],
    ignoreErrors: [/_st\.util\.log/]
  })
}

app.use(createPinia())
app.use(createHead())
app.use(router)

app.mount('#app')
