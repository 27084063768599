<script setup lang="ts">
import config, { AppMode } from '@/config'
import { useHead } from '@unhead/vue'
import { computed } from 'vue'

const appMode = config.appMode

if (appMode === AppMode.Used) {
  useHead({
    link: [
      {
        rel: 'preload',
        href: '/img/leon.webp',
        as: 'image',
        type: 'image/webp',
        imagesrcset: '/img/leon.webp 1440w, /img/leon@2x.webp 2880w',
        imagesizes: '100vw',
        fetchpriority: 'high'
      }
    ]
  })
} else {
  useHead({
    link: [
      {
        rel: 'preload',
        href: '/img/steering-wheel@2x.webp',
        as: 'image',
        type: 'image/webp',
        fetchpriority: 'high'
      }
    ]
  })
}

const bgClass = computed(() => {
  if (appMode === AppMode.Used) {
    return 'bg-[url(/img/leon@2x.webp)]'
  } else {
    return 'bg-[url(/img/steering-wheel@2x.webp)]'
  }
})
</script>

<template>
  <header
    class="h-96 w-full h-96 flex items-center relative bg-cover bg-[center_center]"
    :class="bgClass"
  >
    <div class="bg-black opacity-30 w-full h-full absolute"></div>
    <div class="container mx-auto px-4 relative">
      <h1 class="text-white font-light text-5xl mb-2">
        <template v-if="appMode === AppMode.Used">
          Snel rijden in een <br class="hidden lg:inline" />CUPRA Approved occasion
        </template>
        <template v-else-if="appMode === AppMode.New">
          Direct een CUPRA<br class="hidden lg:inline" />
          uit voorraad rijden
        </template>
      </h1>
    </div>
  </header>
</template>
