<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import NavBar from './components/NavBar.vue'
import { watch } from 'vue'
import { useHead } from '@unhead/vue'
import Cookies from 'js-cookie'
import config, { COMPARISON_COOKIE_NAME } from './config'

declare global {
  interface Window {
    utag?: any
  }
}

const route = useRoute()

watch(route, (route) => {
  if (route.query.comparison) {
    Cookies.set(COMPARISON_COOKIE_NAME, route.query.comparison as string)
  }

  window.utag &&
    window.utag.view({
      url_of_page: route.fullPath,
      brand: config.brandName,
      page_category: route.name
    })
})

useHead({
  titleTemplate: (title?: string) => (!title ? config.appName : `${title} | ${config.appName}`)
})
</script>

<template>
  <NavBar />

  <RouterView />
</template>
