<script setup lang="ts">
import router from '@/router'
import { useIlsaStore } from '@/stores/ilsa'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { useHead } from '@unhead/vue'
import { toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { VueToggles } from 'vue-toggles'
import FooterPart from '@/components/FooterPart.vue'

const props = defineProps<{
  ids: String
}>()

const ilsaStore = useIlsaStore()
const route = useRoute()

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `${import.meta.env.VITE_BASE_URL || `${window.location.protocol}//${window.location.host}`}${route.fullPath}`
    }
  ]
})

const ids = new Set<String>(props.ids.split(','))
const { compareOnlyDifferences } = toRefs(ilsaStore)

watch(
  () => ilsaStore.compareOnlyDifferences,
  () => {
    ilsaStore.retrieveComparison()
  }
)

ilsaStore.compareVehicleIds = ids
ilsaStore.retrieveComparison()

const removeVehicleFromComparison = (id: string) => {
  ilsaStore.toggleVehicleIdForComparison(id)
  router.replace(`/vergelijk/${[...ilsaStore.compareVehicleIds].join(',')}`)
  ilsaStore.retrieveComparison()
}
</script>

<template>
  <main v-if="ilsaStore.comparison">
    <div class="container mx-auto mt-4 px-4">
      <a @click.prevent="$router.back()" class="text-sm cursor-pointer hover:underline block mb-4"
        >&lt; Terug naar overzicht</a
      >
      <h1 class="text-3xl mb-4">Vergelijk auto's</h1>

      <div class="flex flex-row gap-3 my-4">
        <VueToggles
          id="only-differences"
          v-model="compareOnlyDifferences"
          width="50"
          checked-bg="#003E51"
          unchecked-bg="#A4A4A4"
        />
        <label for="only-differences"> Toon alleen verschillen </label>
      </div>

      <div class="overflow-x-scroll">
        <table
          cellspacing="4"
          cellpadding="4"
          class="w-full text-sm text-left table-fixed overflow-scroll min-w-max"
        >
          <thead class="bg-cupragray text-white">
            <tr>
              <th></th>
              <th v-for="(vehicle, ix) in ilsaStore.comparison.vehicles" :key="ix" class="py-4">
                <div class="flex gap-4">
                  <h2 class="text-2xl font-light">
                    {{ vehicle.general.make.name }} {{ vehicle.general.model.name }}
                  </h2>
                  <button
                    v-if="ilsaStore.compareVehicleIds.size > 2"
                    class="p-1"
                    @click="removeVehicleFromComparison(vehicle.id)"
                  >
                    <XMarkIcon class="inline-block h-4 w-4 mb-0.5" />
                  </button>
                </div>
                <div class="font-bold md:line-clamp-1 mb-4">
                  {{ vehicle.general.type.name }}
                </div>
                <RouterLink
                  :to="`/${vehicle.identification.slug}/${vehicle.id}`"
                  class="block cursor-pointer mb-4"
                >
                  <img :srcset="vehicle.images[0].srcset" class="w-64 h-32 object-cover" />
                </RouterLink>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(section, ix) in ilsaStore.comparison.sections" :key="ix">
              <tr class="bg-oxygen">
                <td
                  :colspan="ilsaStore.comparison.vehicles.length + 1"
                  class="uppercase font-bold text-xl pt-6 pb-2"
                >
                  {{ section.title }}
                </td>
              </tr>

              <template v-for="(subsection, ix) in section.subsections" :key="ix">
                <!-- {{ subsection.title }} -->
                <tr v-for="(field, ix) in subsection.fields" :key="ix" class="border-b">
                  <td class="uppercase text-gray-500 text-xs">{{ field.label }}</td>
                  <td v-for="(vehicleField, ix) in field.vehicles" :key="ix">
                    {{ vehicleField.display_value || '-' }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </main>

  <FooterPart id="disclaimer" />
</template>
